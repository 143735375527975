import Head from 'next/head'
import { useRouter } from 'next/router'
import LogoMobile from 'public/images/mobile-logo.svg'
import { useEffect } from 'react'
import useAccount from 'src/auth/useAccount'
import FirebaseAuth, { AuthProvider } from 'src/components/Auth/FirebaseAuth'
import Logo from 'src/components/Logo'
import { getBaseUrl } from 'src/helpers'
import { appPageURL } from 'src/initAuth'
import { usePageTracker } from 'src/tracking/hooks/usePageTracker'

export const getAuthUrlWithRedirect = (path: string) =>
  `/auth/?redirect=${encodeURIComponent(path)}`

const vercelPreviewUrlRegex =
  /^flow-builder-([0-9a-zA-Z-]+)-arcadehq\.vercel\.app$/

interface Props {
  providers: AuthProvider[]
}

const AuthPage = ({ providers }: Props) => {
  const account = useAccount()
  const router = useRouter()

  let redirect: string | null = null
  if (router.query) {
    if (typeof router.query.redirect === 'string') {
      redirect = router.query.redirect
    }
  }

  const isVercelPreview = vercelPreviewUrlRegex.test(window.location.host)

  usePageTracker('Login', {})

  useEffect(() => {
    window?.Intercom?.('shutdown')

    if (!router) {
      return
    }

    if (account.isLoggedIn && account.userEmailVerified) {
      // If user is already logged in, redirect based on URL parameter (or else to the default page)
      if (
        redirect &&
        (redirect.startsWith('/') || redirect.startsWith(getBaseUrl())) // Don't allow redirects outside of the app
      ) {
        router.push(redirect)
      } else {
        router.push(appPageURL)
      }
    } else if (isVercelPreview) {
      // If user is not logged in, and this is a Vercel preview deployment,
      // redirect to a special route that associates our custom preview domain
      // to the Vercel deployment and redirect back to it, so we can properly
      // authenticate there.
      router.push('/api/vercel/preview')
    }
  }, [
    account.isLoggedIn,
    account.userEmailVerified,
    router,
    redirect,
    isVercelPreview,
  ])

  if (account.isLoggedIn || isVercelPreview) {
    return null
  }
  return (
    <>
      <Head>
        <title>Welcome to Arcade!</title>
      </Head>
      <div className='flex items-center min-h-screen bg-white dark:bg-gray-900'>
        <div className='container mx-auto'>
          <div className='max-w-md mx-auto my-10 px-6'>
            <div className='text-center'>
              <Logo className='h-6 hidden sm:block mx-auto' square={false} />
              <LogoMobile className='block sm:hidden mx-auto w-8' />
              <h1 className='my-7 text-3xl md:text-4xl font-bold'>
                Welcome to Arcade
              </h1>
            </div>
            <FirebaseAuth providers={providers} />
          </div>
        </div>
      </div>
    </>
  )
}

export default AuthPage
