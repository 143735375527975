import type {
  Analytics,
  AnalyticsBrowserSettings,
  InitOptions,
  Options,
  SegmentEvent,
} from '@arcadehq/analytics-next'
import { AnalyticsBrowser } from '@arcadehq/analytics-next'

import { isPreviewEnv, isProductionEnv, isStagingEnv } from '../helpers'
import { PageTrackerOptions } from './hooks/types'
import { PageEvents, PageName, PageProperties } from './pages'
import { propagateEvent } from './propagateEvent'

const prodApiDomain = 't.arcade.show'
const stagingApiDomain = 'staging.arcade.software'
const localApiDomain = 'localhost:3000'
const isLocal = !isProductionEnv() && !isPreviewEnv()
const isStaging = isStagingEnv()

const analyticsSettings: AnalyticsBrowserSettings = {
  writeKey: '',
  cdnSettings: {
    integrations: {
      'Segment.io': {
        // https://github.com/segmentio/analytics-next/blob/45cfa210f1367b229adbebe83d7e1c889a973386/src/plugins/segmentio/index.ts#L60
        apiHost: `${
          isLocal
            ? localApiDomain
            : isStaging
            ? stagingApiDomain
            : prodApiDomain
        }/api/ingest`,
        protocol: isLocal ? 'http' : 'https',
      },
    },
  },
}

const analyticsOptions: InitOptions = {
  cookie: {
    sameSite: 'None',
    secure: true,
  },
}

class SegmentLoader {
  mock: boolean
  analytics: AnalyticsBrowser
  loaded: boolean = false

  constructor(mock: boolean) {
    this.mock = mock
    this.analytics = new AnalyticsBrowser()
  }

  init() {
    if (this.mock) {
      return
    }

    if (this.loaded) {
      return
    }

    this.analytics.load(analyticsSettings, analyticsOptions)
    this.loaded = true
  }

  identify: Analytics['identify'] = (userId, traits, options) => {
    const timestamp = new Date()
    return this.analytics.identify(userId, traits, {
      ...options,
      timestamp,
    })
  }

  page: Analytics['page'] = (category, name, properties, options) => {
    const timestamp = new Date()
    return this.analytics.page(category, name, properties, {
      ...options,
      timestamp,
    })
  }

  track: Analytics['track'] = (event, properties, options) => {
    const timestamp = new Date()
    return this.analytics.track(event, properties, {
      ...options,
      timestamp,
    })
  }

  trackLink: Analytics['trackLink'] = (links, event, properties, options) => {
    const timestamp = new Date()
    return this.analytics.trackLink(links, event, properties, {
      ...options,
      timestamp,
    })
  }
}

const isServerSide = typeof window === 'undefined'

// Mock Segment if:
// - Server-side
// - Vercel preview env (tracking gets rejected from those origins)
// - Local env (remove `isLocal` to test tracking with local Pub/Sub emulator)
const shouldMockSegment = isServerSide || isPreviewEnv() || isLocal

const segment = new SegmentLoader(shouldMockSegment)

class EventTracker<PN extends PageName> {
  private userId: string | null
  private readonly pageName: PN
  private pageProperties: PageProperties<PN>
  private shouldPublishToHost: boolean
  private readonly options: Options

  constructor(
    pageName: PN,
    pageProperties: PageProperties<PN>,
    options?: PageTrackerOptions
  ) {
    const {
      shouldPublishToHost = false,
      shouldAnonymizeIP = false,
      doNotTrack = false,
    } = options ?? {}

    this.userId = null
    this.pageName = pageName
    this.pageProperties = pageProperties
    this.shouldPublishToHost = shouldPublishToHost
    this.options = {
      ...(shouldAnonymizeIP ? { context: { ip: '0.0.0.0' } } : {}),
    }

    if (!doNotTrack) {
      segment.init()
    }
  }

  // Used in conjunction with `doNotTrack`. This allows us to enable tracking
  // only after a user consents to cookies.
  enableTracking() {
    segment.init()
  }

  setPageProperties(pageProperties: PageProperties<PN>) {
    this.pageProperties = pageProperties
  }

  updatePageProperties(update: Partial<PageProperties<PN>>) {
    this.pageProperties = {
      ...this.pageProperties,
      ...update,
    }
  }

  updateShouldPublishToHost(shouldPublishToHost: boolean) {
    this.shouldPublishToHost = shouldPublishToHost
  }

  setUser(newUserId: string, newUserEmail: string | null) {
    if (this.userId !== newUserId) {
      this.userId = newUserId
      segment.identify(newUserId, { email: newUserEmail }, this.options)
    }
  }

  page() {
    segment.page(undefined, this.pageName, this.pageProperties, this.options)

    if (this.shouldPublishToHost && this.pageName === 'Viewer') {
      propagateEvent(
        'Viewer',
        'Flow Rendered',
        this.pageProperties as PageProperties<'Viewer'>,
        {}
      )
    }
  }

  report<EventName extends keyof PageEvents<PN>>(
    eventName: EventName,
    eventProperties: PageEvents<PN>[EventName]
  ) {
    segment.track(
      eventName as string,
      {
        ...this.pageProperties,
        ...eventProperties,
        name: this.pageName,
      },
      this.options
    )
    if (this.shouldPublishToHost) {
      propagateEvent(
        this.pageName,
        eventName,
        this.pageProperties,
        eventProperties
      )
    }
  }

  attachLink<EventName extends keyof PageEvents<PN>>(
    anchorElement: HTMLAnchorElement,
    eventName: EventName,
    eventProperties: PageEvents<PN>[EventName]
  ) {
    segment.trackLink(
      anchorElement,
      eventName as string,
      {
        ...this.pageProperties,
        ...eventProperties,
      } as SegmentEvent['properties'],
      this.options
    )
  }
}

export default EventTracker
